<template>
  <article class="text-image-block layout__main">
    <div class="text-image-block__content">
      <div class="text-image-block__column-left">
        <slot name="title" />

        <slot name="description" />
        <slot name="cta" />
      </div>
      <div class="text-image-block__column-right">
        <img
          :src="image"
          class="text-image-block__image"
          alt="Iwana Puhalovic IPMakelaardij"
        />
      </div>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
    },
  },
};
</script>

<style>
.text-image-block {
  display: flex;
  justify-content: center;
  margin-bottom: 4.5rem;
  width: 100%;
  background-color: var(--color-secondary);
  /* background-image: linear-gradient( to bottom, var(--color-secondary) 70%, var(--color-tertiary)); */
}

.text-image-block__content {
  display: flex;
  flex-direction: column-reverse;
  max-width: 40.25rem;
}

.text-image-block__column-right {
  display: flex;
  flex-direction: column;
  margin: 0 1.5rem;
}

.text-image-block__image {
  object-fit: cover;
  aspect-ratio: 1/1;
  width: 100%;

  border: 1px solid var(--color-darkest);
  border-radius: 50%;
  mix-blend-mode: multiply;
}

@media screen and (min-width: 950px) {
  .text-image-block__content {
    flex-direction: row;
    gap: 2rem;
    max-width: 100%;
  }

  .text-image-block__column-right {
    width: 200%;
    margin: 0;
  }
}

@media screen and (min-width: 1100px) {
  .text-image-block__content {
    gap: 12rem;
  }
}
</style>
