<template>
  <div class="the-cursor"></div>
</template>

<style scoped>
.the-cursor {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.875rem;
  height: 1.875rem;
  border: 1px solid var(--color-darkest);
  background: var(--color-tertiary);
  border-radius: 50%;
}

.the-cursor:hover a {
  background: var(--color-darkest);
}

@media screen and (max-width: 950px) {
  .the-cursor {
    display: none;
  }
}
</style>
