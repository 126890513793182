<template>
    <article class="the-footer layout__main">
        <div class="the-footer__content">
            <div class="the-footer__column-left">
                <h5 class="the-footer__logo">IP MAKELAARDIJ</h5>
                 <ul>
                    <li>Iwana Puhalovic</li>
                    <li><a href="tel:+31649106465">06 4910 6465</a></li>
                    <li><a href="mailto:welkom@ipmakelaardij.nl">welkom@ipmakelaardij.nl</a></li>
                </ul>
            </div>
             
            <div class="the-footer__column-right">
                <ul>
                    <li><a href="https://www.linkedin.com/in/iwanapuhalovic">LinkedIn <i class="fa-brands fa-linkedin"></i></a></li>
                    <li>©2024 IP Makelaardij</li>
                </ul>
            </div>
        </div>
    </article>
</template>

<style>
.the-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--color-secondary);
}

.the-footer a:hover {
    color: var(--color-tertiary);
    text-decoration: underline;
}

@media screen and (min-width: 950px) {
    .the-footer__content {
        display: flex;
        justify-content: space-between;
    }

    .the-footer__column-right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }
}

@media screen and (min-width: 1444px) {
    .the-hero__content {
        max-width: 1444px;
    }
}
</style>