<template>
    <div class="base-rotator">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="200px" height="200px" viewBox="0 0 150 100" enable-background="new 0 0 150 150" xml:space="preserve">
            <path id="textPath" fill="none" d="M89.322,50.197c0,22.09-17.91,40-40,40c-22.089,0-40-17.91-40-40 c0-22.089,17.911-40,40-40C71.412,10.197,89.322,28.108,89.322,50.197z"/>
            <text>
                <textPath xlink:href="#textPath"><tspan x="0">I P M A K E L A A R D I J </tspan><tspan x="77" dx="60">A M S T E R D A M</tspan></textPath>
            </text>
        </svg>
    </div>
</template>
    
<style scoped>
.base-rotator {
  position: relative;
  z-index: 1;
  display: inline-block;

}

svg {
    position: relative;
    z-index: -1;
    display: block;
}

text {
    font-size: .6875rem;
    transform-origin: center;
}

/* Animation */
@keyframes rotate-btn {
    to{ transform: rotate(360deg); }
}
.base-rotator text {
    animation: rotate-btn 10s linear infinite;
}
</style>