<template>
    <div
        class="base-button"
    >
        <slot />
    </div>
</template>

<style>
.base-button {
    position: relative;
    display: flex;
    flex-direction: column;
    width: fit-content;
    
    background-color: var(--color-tertiary);
    border: 1px solid var(--color-darkest);
    border-radius: 1.25rem;
    transition: all 0.3s ease-in-out;
}

.base-button a {
    font-size: var(font-size-base);
    text-underline-offset: none;
    padding: .5rem 1.3rem;
}

.base-button:hover {
        background-color: var(--color-darkest);
    }

.base-button:hover a {
    color: var(--color-tertiary);
}

@media screen and (min-width: 768px) {
    .base-button {
        border-radius: 1.875rem;
    }

    .base-button a {
        padding: .625 1.875rem;
    }
}
</style>