<template ref="mousePosition">
  <article class="home layout" ref="top">
    <TheNavBar @scroll-event="scrollToTop" style="position: sticky; top: 0" />
    <TheHero>
      <template #title>
        <h1 v-html="title" class="home__title" />
      </template>

      <template #content>
        <BaseRotator />
      </template>
    </TheHero>

    <TheCursor
      :style="{ left: x - 30 + 'px', top: y - 30 + 'px' }"
      @mousemove="onMousemove"
    />

    <section class="layout__content">
      <h2>Ben je van plan je woning te verkopen?</h2>
      <p>
        Ik kom graag bij je langs om jouw woning te bekijken en kennis met je te
        maken. Je ontvangt advies op maat. Als we gaan samenwerken dan begeleid
        ik je het gehele verkoopproces tot aan de overdracht van jouw woning.
      </p>
    </section>

    <section class="layout__content">
      <h2>Wel of geen aankoopmakelaar in de arm nemen?</h2>
      <p>
        Een (nieuw) huis kopen is leuk en spannend. Het is een grote investering
        en er komt veel op je af. Veel informatie kun je online vinden, maar je
        kunt ook een professional inschakelen.
      </p>
    </section>

    <section class="layout__content layout__end">
      <h2>Wat doet een aankoopmakelaar eigenlijk?</h2>

      <p>
        Een aankoopmakelaar helpt je bij het vinden en kopen van een huis. Een
        aankoopmakelaar is goed op de hoogte van de lokale woningmarkt en weet
        wat er te koop is. Zo zit je als koper dicht bij het vuur. Handig,
        vooral als je een woning wilt kopen in een gewilde regio, zoals
        Amsterdam.
      </p>

      <p>
        Een aankoopmakelaar zorgt dat jij als eerste op de hoogte bent van nieuw
        aanbod, attendeert jou op belangrijke zaken, leest documenten voor je
        door en zorgt ervoor dat je goed geïnformeerd bent.
      </p>

      <p>
        Een aankoopmakelaar doet onderzoek en informeert jou over de waarde van
        een woning, waarna je kunt besluiten of je wel of geen bod wil
        uitbrengen, wat ook door jouw makelaar wordt gedaan.
      </p>

      <p>
        Ik help je graag met alle zaken die komen kijken bij het kopen van een
        nieuwe woning. Van het begin tot aan de feestelijke sleuteloverdracht
        bij de notaris. Jij hoeft alleen nog maar te voelen of het huis bij je
        past.
      </p>

      <p>
        Ben je van plan om jouw huis te verkopen? Of ben je (eerst) op zoek naar
        een nieuwe woning? neem dan contact met me op voor een vrijblijvende
        afspraak om jouw wensen te bespreken.
      </p>

      <p>Hopelijk tot gauw!</p>
    </section>

    <TextImageBlock
      :image="require(`../assets/images/ip-makelaardij-iwana-puhalovic.jpg`)"
      alt="Iwana Puhalovic Makelaar in Amsterdam"
    >
      <template #title>
        <h2>Over Iwana&nbsp;Puhalovic</h2>
      </template>

      <template #description>
        <section class="layout__end">
          <p>
            18 jaar geleden ben ik in de makelaardij begonnen en nog altijd ga
            ik elke dag met veel plezier aan het werk. Ik mag werken in (en om)
            Amsterdam! Wat mij betreft, de mooiste stad die er is. Amsterdam
            bruist. Ik hou van de straatjes, de verschillende bouwstijlen en de
            veelzijdigheid aan mensen.
          </p>
        </section>
      </template>

      <template #cta>
        <BaseButton>
          <a href="mailto:welkom@ipmakelaardij.nl" target="_blank"
            ><i class="fa-solid fa-envelope"></i> Mail voor een afspraak</a
          >
        </BaseButton>
      </template>
    </TextImageBlock>

    <section class="layout__content layout__end">
      <h2>Wil jij ook persoonlijk advies?</h2>

      <p>
        Mijn werk is niet alleen het aankopen of verkopen van een huis. Het is
        mensenwerk, gebaseerd op vertrouwen. Het gaat over een spannende en
        emotionele gebeurtenis die iemand maar eens of een paar keer in zijn of
        haar leven meemaakt.
      </p>

      <p>
        Ik werk met mijn hart, neem graag de tijd voor mijn klanten en ben
        persoonlijk betrokken, wat mijn werk nog meer voldoening geeft. Ik mag
        elke dag werken in de mooiste stad én deel uitmaken van een bijzondere
        gebeurtenis in iemands leven!
      </p>

      <p>Graag ga ik ook met jou een mooie samenwerking aan!</p>
    </section>
    <TheFooter />
  </article>
</template>

<script setup>
import TheHero from "@/components/TheHero.vue";
import BaseButton from "@/components/BaseButton.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheNavBar from "@/components/TheNavBar.vue";
import TextImageBlock from "@/components/TextImageBlock.vue";
import BaseRotator from "@/components/BaseRotator.vue";
import TheCursor from "@/components/TheCursor.vue";

import { ref, onMounted, onUnmounted } from "vue";

const title = `Persoonlijk advies<br>bij al jouw woonvragen`;

//Mouse tracker
const x = ref(0);
const y = ref(0);

function update(event) {
  x.value = event.pageX;
  y.value = event.pageY;
}

onMounted(() => window.addEventListener("mousemove", update));
onUnmounted(() => window.removeEventListener("mousemove", update));

//Scroll to top
const top = ref(null);

function scrollToTop() {
  top.value.scrollIntoView({ behavior: "smooth" });
}
</script>
