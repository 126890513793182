<template>
    <article class="the-hero">
        <div class="the-hero__content">
            <slot name="title" />
            <slot name="content" />
        </div>
    </article>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
    }
  }
}
</script>

<style>
.the-hero {
    display: flex;
    justify-content: center;
    margin-bottom: 4.5rem;
    padding: 4rem 1.5rem;
    width: 100%;
    background-color: var(--color-secondary);
    /* background-image: linear-gradient( to bottom, var(--color-secondary) 70%, var(--color-tertiary)); */
}

@media screen and (min-width: 1444px) {
    .the-hero__content {
        max-width: 1444px;
    }
}
</style>